import {
  PAYROLL_MANAGEMENT_ACCESS,
  SALARY_POLICY_ACCESS,
  PROJECT_COMMISSION_POLICY_ACCESS,
  MEAL_POLICY_ACCESS,
  OVER_TIME_POLICY_ACCESS,
  INSURANCE_POLICY_ACCESS,
  INCOME_TAX_POLICY_ACCESS,
  FESTIVAL_BONUS_POLICY_ACCESS,
  DELAY_FINE_POLICY_ACCESS,
  FINE_POLICY_ACCESS,
  SALARY_PAYSLIP_ACCESS,
  EXTRA_LEAVE_POLICY_ACCESS,
  PROVIDENT_FUND_POLICY_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Payroll",
    icon: "CalendarIcon",
    gate: PAYROLL_MANAGEMENT_ACCESS,
    class: 'payroll-tour',
    children: [
      {
        title: "Policy",
        gate: PAYROLL_MANAGEMENT_ACCESS,
        children: [
          {
            title: "Salary",
            route: "admin-salary-policy",
            gate: SALARY_POLICY_ACCESS,
          },
          {
            title: "Project Commission",
            route: "admin-project-commission-policies",
            gate: PROJECT_COMMISSION_POLICY_ACCESS,
          },
          {
            title: "Meal",
            route: "admin-meal-policies",
            gate: MEAL_POLICY_ACCESS,
          },
          {
            title: "Over Time",
            route: "admin-over-time-policy",
            gate: OVER_TIME_POLICY_ACCESS,
          },
          {
            title: "Extra Leave",
            route: "admin-extra-leave-policy",
            gate: EXTRA_LEAVE_POLICY_ACCESS,
          },
          {
            title: "Insurance",
            route: "admin-insurance-policy",
            gate: INSURANCE_POLICY_ACCESS,
          },
          {
            title: "Provident Fund",
            route: "admin-provident-fund-policy",
            gate: PROVIDENT_FUND_POLICY_ACCESS,
          },
          // {
          //   title: "Income Tax",
          //   route: "admin-income-tax-policy",
          //   gate: INCOME_TAX_POLICY_ACCESS,
          // },
          {
            title: "Festival Bonus",
            route: "admin-festival-bonus-policies",
            gate: FESTIVAL_BONUS_POLICY_ACCESS,
          },
          {
            title: "Fine",
            route: "admin-fine-policies",
            gate: FINE_POLICY_ACCESS,
          },
          {
            title: "Late Fine",
            route: "admin-delay-fine-policies",
            gate: DELAY_FINE_POLICY_ACCESS,
          },
        ],
      },
      {
        title: "Salary Payslip",
        route: "admin-salary-payslip",
        gate: SALARY_PAYSLIP_ACCESS,
      },
    ],
  },
];
