<template>
  <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
      :key="$route.fullPath"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="$route.meta.homePatch">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                  v-for="item in breadcrumbItems"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BBreadcrumb, BBreadcrumbItem, BButton, BCol, BDropdown, BDropdownItem, BRow,} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      breadcrumbItems: [] // Initialize as an array
    }
  },
  watch: {
    // Watch route changes
    $route(to, from) {
      this.updateCrumb(to)
    }
  },
  methods: {
    updateCrumb(route) {
      // Check if the route has a breadcrumb in the meta field
      if (route.meta && route.meta.breadcrumb) {

        // console.log(route.meta.breadcrumb)

        // Clone and modify the breadcrumb items
        this.breadcrumbItems = route.meta.breadcrumb.map(breadcrumb => {
          const updatedItem = { ...breadcrumb };

          // Modify the `to` property based on route params (e.g., replace :id with actual param)
          if (updatedItem.to && typeof updatedItem.to === 'string') {
            updatedItem.to = updatedItem.to.replace(/:(\w+)/g, (_, key) => {
              return route.params[key] !== undefined ? route.params[key] : '';
            });
          }

          // Modify the `text` property if it has dynamic placeholders (optional)
          if (updatedItem.text && typeof updatedItem.text === 'string') {
            updatedItem.text = updatedItem.text.replace(/:(\w+)/g, (_, key) => {
              return route.params[key] !== undefined ? route.params[key] : '';
            });
          }

          return updatedItem
        });
      }
    }
  },
};
</script>
