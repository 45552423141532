import {
  ASSET_MANAGEMENT_ACCESS,
  ASSET_TYPE_ACCESS,
  ASSET_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Asset",
    icon: "LayersIcon",
    gate: ASSET_MANAGEMENT_ACCESS,
    class: 'asset-tour',
    children: [
      {
        title: "Category",
        route: "admin-asset-categories",
        gate: ASSET_TYPE_ACCESS,
      },

      {
        title: "Asset",
        route: "admin-assets",
        gate: ASSET_ACCESS,
      },
    ],
  },
];
