<template>
  <b-nav-item>
    <template v-if="!isLoading">
      <template v-if="isCheck">
        <b-button
          id="tooltip-check-out"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.bottom.v-danger
          variant="danger"
          title="Check Out"
          size="sm"
          class="btn-icon rounded-circle mr-1"
          v-on:click="onCheckOut()"
        >
          <feather-icon size="21" icon="CheckCircleIcon" />
        </b-button>
      </template>

      <template v-else>
        <b-button
          id="tooltip-check-out"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.bottom.v-success
          variant="success"
          title="Check In"
          size="sm"
          class="btn-icon rounded-circle mr-1"
          v-on:click="onCheckIn()"
        >
          <feather-icon size="21" icon="ClockIcon" />
        </b-button>
      </template>
    </template>

    <template v-else>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon rounded-circle mr-1"
        size="sm"
        disabled
      >
        <b-spinner small />
        <span class="sr-only">Loading...</span>
      </b-button>
    </template>
  </b-nav-item>
</template>

<script>
import { BButton, VBTooltip, BNavItem, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { EventBus } from "@/helpers/event-bus";

export default {
  name: "AttendanceCheckInOut",
  components: {
    BButton,
    BNavItem,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      isCheck: false,
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      const res = await this.$api.get("/api/attendances/is-check-in");

      this.isLoading = false;
      this.isCheck = res?.data?.data;
    } catch (error) {
      this.isLoading = false;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async onCheckIn() {
      try {
        this.isLoading = true;
        await this.$api.post("/api/attendances/check-in");
        EventBus.$emit("changed-check-in-out");
        this.isLoading = false;
        this.isCheck = true;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Check in successful",
          },
        });

      } catch (error) {
        this.isLoading = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onCheckOut() {
      try {
        this.isLoading = true;
        await this.$api.post("/api/attendances/check-out");
        EventBus.$emit("changed-check-in-out");
        this.isLoading = false;
        this.isCheck = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Check out successful",
          },
        });

      } catch (error) {
        this.isLoading = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>
