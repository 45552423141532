import { ROLES_ACCESS } from "@/helpers/permissionsConstant";

export default [
  // {
  //   header: "Leave",
  // },
  {
    title: "Role & Permission",
    route: "admin-roles",
    icon: "ShieldIcon",
    gate: ROLES_ACCESS,
    class: 'role-tour'
  },
];
