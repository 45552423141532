import {
  ATTENDANCE_POLICY_ACCESS,
  ATTENDANCE_POLICY_MAP_ACCESS,
  ATTENDANCE_MANAGEMENT_ACCESS,
  ATTENDANCE_CALENDER_ACCESS,
  ATTENDANCE_ACCESS,
  ATTENDANCE_REPORT_ACCESS,
  ATTENDANCE_RECONCILIATION_ACCESS,
  DIVISION_ACCESS,
  DEPARTMENT_ACCESS,
  DESIGNATION_ACCESS,
  USERS_ACCESS,
  LEAVE_BALANCE_ACCESS,
  LEAVE_MANAGEMENT_ACCESS,
  LEAVE_TYPES_ACCESS,
  LEAVE_REQUESTS_ACCESS,
  LEAVE_APPROVE_ACCESS,
  NOTICE_ACCESS,
  HRMS_ACCESS,
  EMPLOYEE_TYPES_ACCESS,
  LEAVE_APPROVE_DEPT_HEAD_ACCEPT, LEAVE_APPROVE_DIVISION_HEAD_ACCEPT,
  LEAVE_APPROVE_ACCEPT, ATTENDANCE_CHECK_IN_OUT, REMOTE_ATTENDANCE_ACCESS
} from "@/helpers/permissionsConstant";

export default [
  // {
  //   header: "Attendance Management",
  // },
  {
    title: "HRMS",
    icon: "UsersIcon",
    gate: HRMS_ACCESS,
    class: 'hrms-tour',
    children: [
      {
        title: "Employees",
        icon: "UsersIcon",
        route: "admin-users",
        gate: USERS_ACCESS,
      },
      {
        title: "Employee Types",
        icon: "CodepenIcon",
        route: "admin-employee-types",
        gate: EMPLOYEE_TYPES_ACCESS,
      },
      {
        title: "Division",
        icon: "CodepenIcon",
        route: "admin-division",
        gate: DIVISION_ACCESS,
      },
      {
        title: "Department",
        icon: "CodepenIcon",
        route: "admin-department",
        gate: DEPARTMENT_ACCESS,
      },
      {
        title: "Designation",
        icon: "CodepenIcon",
        route: "admin-designation",
        gate: DESIGNATION_ACCESS,
      },
      {
        title: "Attendance",
        icon: "BarChart2Icon",
        gate: ATTENDANCE_MANAGEMENT_ACCESS,
        children: [
          {
            title: "Calender",
            route: "admin-attendance-calender",
            gate: ATTENDANCE_CALENDER_ACCESS,
          },
          {
            title: "Attendance",
            route: "admin-attendance",
            gate: ATTENDANCE_ACCESS,
          },
          {
            title: "Reconciliation",
            route: "admin-attendance-reconciliations",
            gate: ATTENDANCE_RECONCILIATION_ACCESS,
          },
          {
            title: "Report",
            route: "admin-attendance-report",
            gate: ATTENDANCE_REPORT_ACCESS,
          },
          {
            title: "Policy",
            route: "admin-attendance-policy",
            gate: ATTENDANCE_POLICY_ACCESS,
          },
          {
            title: "Policy Map",
            route: "admin-attendance-policy-map",
            gate: ATTENDANCE_POLICY_MAP_ACCESS,
          },
          {
            title: "Remote Attendance",
            route: "admin-remote-attendance",
            gate: REMOTE_ATTENDANCE_ACCESS,
          },
        ],
      },
      {
        title: "Leave",
        icon: "ClockIcon",
        gate: LEAVE_MANAGEMENT_ACCESS,
        children: [
          {
            title: "Leave Balance",
            route: "leave-balance",
            gate: LEAVE_BALANCE_ACCESS,
          },
          {
            title: "Leave Types",
            route: "admin-leave-types",
            gate: LEAVE_TYPES_ACCESS,
          },
          {
            title: "Apply Leave",
            route: "admin-leave-requests",
            gate: LEAVE_REQUESTS_ACCESS,
          },
          {
            title: "Leave Requests",
            route: "admin-leave-approve",
            gate: [LEAVE_APPROVE_DEPT_HEAD_ACCEPT, LEAVE_APPROVE_ACCEPT, LEAVE_APPROVE_DIVISION_HEAD_ACCEPT],
          },
        ],
      },
      {
        title: "Notice",
        icon: "ClipboardIcon",
        route: "admin-notice",
        gate: NOTICE_ACCESS,
      },
    ],
  },
];
