<template>
    <div @click="onClick">
      <div>
        <slot />
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios'
  export default {
    name: 'XDesk',
    props: {
      details: {
        type: Object,
        default: () => ({}),
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
      secretKey: {
        type: String,
        required: true,
      },
      setLoader: {
        type: Function,
        default: () => console.log("setLoader returns loading status"),
      },
    },
    methods: {
      async onClick() {
        this.setLoader(true)
        try {
          const res = await axios.post(
            'https://api.xdesk.pro/api/client',
            {
              details: this.details,
              is_admin: this.isAdmin,
            },
            {
              headers: {
                key: this.secretKey,
              },
            },
          )
          if (res?.data?.status === 200) {
            // window.open(`https://app.xdesk.pro${res?.data?.url}`, '_blank')
            window.open(`${res?.data?.url}`, '_blank')

          } else {
            // eslint-disable-next-line no-alert
            alert(res?.data?.message)
          }
        } catch {
          // eslint-disable-next-line no-alert
          alert('Something went wrong')
        }
        this.setLoader(false)
      },
    },
  }
  </script>