import {
  SETTINGS_ACCESS,
  SETTINGS_GENERAL_ACCESS,
  WEEKENDS_ACCESS,
  HOLIDAYS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  // {
  //   header: "Leave",
  // },
  {
    title: "Settings",
    icon: "SettingsIcon",
    gate: SETTINGS_ACCESS,
    class: 'settings-tour',
    children: [
      {
        title: "General",
        route: "admin-settings-general",
        gate: SETTINGS_GENERAL_ACCESS,
      },
      {
        title: "Weekends",
        route: "admin-settings-weekends",
        gate: WEEKENDS_ACCESS,
      },
      {
        title: "Holidays",
        route: "admin-settings-holidays",
        gate: HOLIDAYS_ACCESS,
      },
    ],
  },
];
