import {
  LOAN_MANAGEMENT_ACCESS,
  LOAN_ACCESS,
  LOAN_POLICY_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Loan",
    icon: "DivideSquareIcon",
    gate: LOAN_MANAGEMENT_ACCESS,
    class: 'loan-tour',
    children: [
      {
        title: "Loan",
        route: "admin-loan",
        gate: LOAN_ACCESS,
      },
      {
        title: "Loan Policy",
        route: "admin-loan-policies",
        gate: LOAN_POLICY_ACCESS,
      },
    ],
  },
];
