import {
  SUBSCRIPTION_ACCESS,
  PRICING_TABLE_ACCESS,
  SUBSCRIPTION_EDIT,
  PAYMENT_HISTORY_ACCESS,
  PAYROLL_MANAGEMENT_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Subscription",
    icon: "DollarSignIcon",
    gate: SUBSCRIPTION_ACCESS,
    children: [
      {
        title: "Pricing Table",
        route: "pricing-table",
        gate: PRICING_TABLE_ACCESS,
      },
      {
        title: "Payment History",
        route: "payment-histories",
        gate: PAYMENT_HISTORY_ACCESS,
      },
    ],
  },
];
